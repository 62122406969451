<template>
	<section class="widget-content">
		<article class="widget-radio-question">
			<p>Question text</p>


			<medium-editor
				:options='options'
					class="textinput"
					v-model="question"
				label="Type question"
				auto-grow
				outlined
				rows="10"
				row-height="2"
				>
				</medium-editor>

			<v-radio-group class="mt-0" v-model="trueResponse">
				<article
					class="widget-radio-question__answer"
					v-for="(response, index) in responses"
					:key="response.id"
					:id="response.id"
				>
					<p>Response #{{ index + 1 }}</p>


					<medium-editor
				:options='options'
					class="textinput"
					label="Response text"
						auto-grow
						outlined
						rows="5"
						row-height="2"
						v-model="response.content"
				>
				</medium-editor>


					<v-radio
						label="This is the correct answer"
						color="black"
						class="mt-4"
						:id="String(response.id)"
						:value="response.id"
						:name="String(response.id)"
					></v-radio>
				</article>
			</v-radio-group>

			<div class="widget-radio-question__answer--button mt-5">
				<v-btn
					@click="addResponse"
					class="admin-primary-button secondary-contrast-background primary-outline mb-5"
					:loading="isLoading"
					>Add response</v-btn
				>
				<!-- <v-btn
					class="admin-primary-button primary-contrast-background ml-5"
					:loading="isLoading"
					:disabled="true"
					>Add response from bank</v-btn
				> -->
			</div>
		</article>
		<article>
			<FormMessage v-if="message" :type="widgetMessageStatus">{{
				message
			}}</FormMessage>
		</article>

		<div class="widget-bullet-list__button">

<v-btn
	class="w-100 admin-primary-button primary-contrast-background"
	:loading="isLoading"
	@click="submit"
				>{{ editedBlock !== null ? "Save" : "Add question" }}</v-btn
>
</div>

	</section>
</template>
<script>
import MediumEditor from 'vuejs-medium-editor'
import 'medium-editor/dist/css/medium-editor.css'
import 'vuejs-medium-editor/src/themes/default.css'
import 'highlight.js/styles/ocean.css'

import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import FormMessage from "@/components/FormMessage.vue";

import TurndownService from 'turndown'
const turndownService = new TurndownService()
export default {
	name: "WidgetPopupTrueFalse",
	components: { FormMessage, MediumEditor },
	props: {
		activePage: {
			type: Object,
			require: true
		},
		editedBlock: {
			type: Object,
			required: false
		},
		currentWidget: {
			type: Object,
			required: true
		},
		message: {
			type: String,
			required: false
		},
		widgetMessageStatus: {
			type: String,
			require: false
		}
	},
	computed: {
		...mapGetters(["isLoading"]),
		content() {
			if (this.editedBlock === null) return;
			return this.editedBlock.block.content;
		},
		editedBlockId() {
			return this.editedBlock?.id;
		}
	},
	watch: {
		editedBlockId: function () {
			this.initGetData();
		},
		trueResponse: function (val) {
			this.responses.forEach(response => (response.isTrue = false));
			this.responses.forEach(response => {
				if (response.id === val) {
					response.isTrue = true;
				}
			});
		}
	},

	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			trueResponse: "",
			question: "",
			responses: [],
			options: {
				placeholder: false,
        toolbar: {
          buttons: ["bold", "italic", "underline"]
        }
      }
		};
	},

	methods: {
		initGetData() {
			if (this.editedBlock === null) {
				this.addResponse();
				return;
			}
			this.question = this.editedBlock?.block.question;
			this.responses = this.editedBlock?.block.options.map(el => {
				if (el.correct === 1) {
					this.trueResponse = el.id;
				}
				return {
					...el,
					isTrue: el.correct
				};
			});
		},
		async submit() {
			this.$emit("setMessage", {
				val: "",
				type: "widget"
			});
			this.$emit("setWidgetMessageStatus", "");

			if (this.trueResponse === "") {
				this.$emit("setMessage", {
					val: "Please choice correct answer",
					type: "widget"
				});
				this.$emit("setWidgetMessageStatus", "error");
				return;
			}
			if (this.question === "" || this.responses[0].content === "") {
				this.$emit("setMessage", {
					val: "Please fill field",
					type: "widget"
				});
				this.$emit("setWidgetMessageStatus", "error");
				return;
			}

			let question = turndownService.turndown(this.question)
			this.responses.forEach((i,v) => {
				this.responses[v].content = turndownService.turndown(i.content)
			})

			const payload = {
				content:
					this.editedBlock !== null
						? {
								course_id: Number(this.$route.params.id),
								chapter_id: this.editedBlock.block.chapter_id,
								page_id: this.editedBlock.block.page_id,
								title: this.editedBlock.block.title,
								head_level: 2,
								question: question,
								css_class: this.editedBlock.block.css_class,
								sort_order: this.editedBlock.block.sort_order
						  }
						: {
								course_id: Number(this.$route.params.id),
								chapter_id: this.activePage.chapter_id,
								page_id: this.activePage.id,
								title: "Radio widget",
								head_level: 2,
								question: question,
								css_class: null,
								sort_order: Number(
									this.activePage.blocks.length
								)
						  },
				blkType: this.currentWidget.blk_type,
				elements: this.responses
			};
			await this.$emit("saveBlock", payload);
		},
		addResponse() {
			this.responses.push({
				id: uuidv4(),
				isTrue: false,
				response: "",
				isNew: true
			});
		}
	},
	mounted() {
		this.initGetData();
	}
};
</script>

<style lang="scss">
.insert-image-container {
	display: none !important;
}
.textinput {
	border: 1px solid #333;
	margin-bottom: 20px;
}

.medium-editor-container {
	padding: 15px 20px;

	p {
		margin-bottom: 0;
	}
}
</style>
